import React, { Component } from 'react'
import { toastr } from 'react-redux-toastr'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { ContentSection, Grid, Row } from '../common/layout'
import PageSetup from '../main/page-setup'

import { BoxWidget, BoxBody } from '../common/widget/box'
import { money_loc, date_time_loc, date_loc } from '../sys/normalize-location'
import { ref_mask } from '../sys/normalize-mask'

import { InputGroupAddon, SelectGroupAddon } from '../common/form'
import { BigButtonDefault, ButtonInfo, Icon } from '../common/ui'
import { CalloutInfo } from '../common/msg/callout'
import { BadgeRed, BadgeYellow, BadgeGreen, Badge, BadgeAqua } from '../common/ui/badge'
import { Anchor } from '../common/helper'

import { obter, obter_desconto, iniciar_pag, limpar, cancelar_pedido } from './carrinho-action'
import { obter_pedidos, obter_pedido_carrinho } from './pedido-action'
import PedidoList from './pedido-list'

const strateg_sit = {
    '11': () => (<Badge align_class="pull-left">Indefinido</Badge>),
    '99': () => (<Badge align_class="pull-left">Cancelada</Badge>),
    '22': () => (<BadgeGreen align_class="pull-left">Pago</BadgeGreen>),
    '12': () => (<BadgeYellow align_class="pull-left">Aguardando Pagamento</BadgeYellow>),
    '33': () => (<BadgeRed align_class="pull-left">Vencido</BadgeRed>)
}

function sit_pedido(sit_pedido) {
    const f_sit = strateg_sit[sit_pedido ?? '11']
    return f_sit()
}


const strateg_tipo = {
    '11': () => (<Badge align_class="pull-left">Indefinido</Badge>),
    '47': () => (<BadgeAqua align_class="pull-left">Cartão de Crédito</BadgeAqua>),
    '49': () => (<BadgeAqua align_class="pull-left">Boleto</BadgeAqua>),
    '50': () => (<BadgeAqua align_class="pull-left">Pix</BadgeAqua>),
}
function tipo_pg(tipo_pg) {
    const f_ = strateg_tipo[tipo_pg ?? '11']
    return f_()
}

const copyToClipBoard = async copyMe => {
    try {
        await navigator.clipboard.writeText(copyMe);
        toastr.success('Sucesso', 'Pix copiado!');
    } catch (err) {
        toastr.error('Ops!', 'A cópia falhou!')
    }
};

class Carrinho extends Component {

    componentDidMount() {
        this.props.obter()
        this.props.obter_pedidos()
    }

    render_itens() {
        const list = this.props.carr?.list
        return (list).map(vo => (
            <tr key={(vo?.tipo ?? '') + '-' + (vo?.id ?? '')}>
                <td>{vo?.qtd ?? 0}</td>
                <td>{vo?.descricao ?? ''}</td>
                <td>{vo?.codigo ?? ''}</td>
                <td>{money_loc(vo?.valor ?? 0)}</td>
            </tr>
        ))
    }


    render_tab_itens() {
        const list = this.props.carr?.list
        if (!Array.isArray(list)) {
            return (
                <CalloutInfo title="Nada por aqui!">
                </CalloutInfo>
            )
        }

        return (
            <div className="col-12 table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Qtd</th>
                            <th>Descricão</th>
                            <th>Código</th>
                            <th>Valor</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.render_itens()}
                    </tbody>
                </table>
            </div>
        )
    }

    render_total() {
        const carr = this.props.carr
        const total = carr?.total ?? {}
        const strateg_formapg = {
            'pix': 'Desconto Pix',
            'boleto': 'Desconto Boleto',
            'ccred': 'Desconto C.Crédito',
            'x': ''
        }
        return (
            <div className="table-responsive">
                <table className="table">
                    <tbody>
                        <tr>
                            <th style={{ width: "50%" }}>Subtotal:</th>
                            <td>{money_loc(total?.subtotal ?? carr?.subtotal)}</td>
                        </tr>
                        <tr>
                            <th>Cupom</th>
                            <td>{total?.cupom ?? carr?.cupom}</td>
                        </tr>
                        <tr>
                            <th>Desconto do Cupom</th>
                            <td>{money_loc(total?.desconto ?? carr?.desconto)}</td>
                        </tr>

                        {carr?.formapg && <tr>
                            <th>{strateg_formapg[carr?.formapg ?? 'x']}</th>
                            <td>{money_loc(total?.desconto_fpg ?? carr?.desconto_fpg)}</td>
                        </tr>
                        }

                        <tr>
                            <th>Total a Pagar:</th>
                            <th>{money_loc(total?.totalpagar ?? carr?.totalpagar)}</th>
                        </tr>
                        {carr?.tipo_pg && <tr>
                            <th>Tipo de Pagamento:</th>
                            <td>{tipo_pg(carr?.tipo_pg)} </td>
                        </tr>}

                        {carr?.sit_pedido && <tr>
                            <th>Situação:</th>
                            <td>{sit_pedido(carr?.sit_pedido)} </td>
                        </tr>}
                    </tbody></table>
            </div>
        )
    }

    constructor(props) {
        super(props)
        this.regatar_desconto = this.regatar_desconto.bind(this)
        this.iniciar_pagamento = this.iniciar_pagamento.bind(this)
        this.canc_pedido = this.canc_pedido.bind(this)
        this.limpar_carrinho = this.limpar_carrinho.bind(this)
        this.chState = this.chState.bind(this);
    }

    chState(name, value, callback) {
        const obj = {}
        obj[name] = value
        this.setState(state => ({ ...state, ...obj }), callback)
    }

    copia_e_cola(e, qrcode) {
        e.preventDefault()
        copyToClipBoard(qrcode);
    }

    regatar_desconto() {
        this.props.obter_desconto(this.state?.wcupom, this.state?.wformapg)
    }

    limpar_carrinho(e) {
        e.preventDefault()
        this.props.limpar()
    }

    iniciar_pagamento(e) {
        e.preventDefault()
        this.props.iniciar_pag()
    }

    canc_pedido(e) {
        e.preventDefault()
        this.props.cancelar_pedido(this.props.carr?.id_pedido)
    }

    render_cupom() {
        const fpgdesc = this.props.carr?.formapgdesc
        return (
            <>
                <Row>
                    <Grid cols="md-12">
                        <SelectGroupAddon label='desconto' name='formapg' icon='fa fa-money'
                            onChange={(e) => {
                                this.chState('wformapg', e.target.value,
                                    this.regatar_desconto
                                );
                            }}
                        >
                            <option></option>
                            <option value="pix">Pagar com Pix {parseFloat(fpgdesc?.desc_pix) > 0 ? '- desconto de '+fpgdesc?.desc_pix+ '%': ''}</option>
                            <option value="boleto">Pagar com Boleto {parseFloat(fpgdesc?.desc_boleto) > 0 ? '- desconto de '+fpgdesc?.desc_boleto+ '%': ''}</option>
                            <option value="ccred">Pagar com Cartão de Crédito {parseFloat(fpgdesc?.desc_ccred) > 0 ? '- desconto de '+fpgdesc?.desc_ccred+ '%': ''}</option>
                        </SelectGroupAddon>
                        <br />
                    </Grid>

                </Row>
                <Row>
                    <Grid cols="md-6">
                        <InputGroupAddon label='Cupom' placeholder='Cupom' icon='fa fa-tags'
                            normalize={ref_mask} maxLength={20}
                            value={this.state?.wcupom}
                            onChange={(e) => this.chState('wcupom', e.target.value)}
                        />
                    </Grid>
                    <Grid cols="md-6">
                        <ButtonInfo
                            align_class=""
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState(state => ({ ...state, ...{ wcupom: '' } }))
                                this.regatar_desconto()
                            }}
                        >
                            Regatar Cupom
                        </ButtonInfo>
                    </Grid>
                </Row>
            </>
        )
    }

    render_botoes() {
        const carr = this.props.carr
        const pode_cancelar = /12|33/.test(carr?.sit_pedido)
        const not_pedido = !carr?.sit_pedido
        const show_pix = carr?.formapg === 'pix' && not_pedido
        const show_boleto = carr?.formapg === 'boleto' && not_pedido
        const show_ccard = carr?.formapg === 'ccred' && not_pedido
        return (
            <div>
                {show_pix && <BigButtonDefault
                    disabled={this.state?.wcupom}
                    align_class="btn-lg"
                    onClick={(e) => { this.iniciar_pagamento(e) }}
                >
                    <img src="../../dist/img/credit/pix-48.png" style={{ width: "24px" }} alt="Pix" />
                    &nbsp;Gerar QR Code de Pix
                </BigButtonDefault>
                }

                {show_boleto && <BigButtonDefault
                    disabled={this.state?.wcupom}
                    align_class="btn-lg"
                    onClick={(e) => { this.iniciar_pagamento(e) }}
                >
                    <Icon icon="fa fa-barcode" />
                    &nbsp;Gerar Link para Boleto
                </BigButtonDefault>
                }


                {show_ccard && <BigButtonDefault
                    disabled={this.state?.wcupom}
                    align_class="btn-lg"
                    onClick={(e) => { this.iniciar_pagamento(e) }}
                >
                    <Icon icon="fa fa-credit-card" />
                    &nbsp;Gerar Link para Cartão de Crédito
                </BigButtonDefault>
                }


                {not_pedido && <BigButtonDefault
                    align_class="btn-lg pull-right"
                    onClick={(e) => { this.limpar_carrinho(e) }}
                >
                    &nbsp;Limpar Carrinho
                </BigButtonDefault>
                }

                {pode_cancelar && <BigButtonDefault
                    align_class="btn-lg pull-right"
                    onClick={(e) => { this.canc_pedido(e) }}
                >
                    &nbsp;Cancelar Pedido
                </BigButtonDefault>
                }
                <br /><br /><br /><br />
                <div className="clearfix"></div>
            </div>
        )
    }

    render_qrcode(carr) {
        return (

            <Row>
                <Grid cols="md-6">
                    <div className="text-center">
                        <img src={carr?.imagemQrcode} style={{ border: '5px solid rgba(0, 0, 0, 0.9)', margin: '10px' }} alt='pix' />
                        <ButtonInfo
                            align_class=""
                            onClick={(e) => { this.copia_e_cola(e, carr?.qrcode) }}
                        >
                            Pix Copia e Cola
                        </ButtonInfo>
                    </div>

                </Grid>
                <Grid cols="md-6">
                    <p className="lead">Leia o QR Code com seu celular, ou use o Botão Copia e Cola para efetuar o pamento.</p>
                    <p className="lead">Validade: <b>{date_time_loc(carr?.expiracao)}</b></p>
                </Grid>
            </Row >
        )
    }


    render_link_pg(carr) {
        return (

            <Row>
                <Grid cols="md-6">
                    <div className="text-center">
                        <Anchor href={carr?.payment_url} target="_blank" className="btn btn-warning btn-lg ">
                            Ir para o Link de Pagamento
                        </Anchor>
                    </div>

                </Grid>
                <Grid cols="md-6">
                    <p className="lead">Acesse o Link de pagamento para prosseguir.</p>
                    <p className="lead">Validade: <b>{date_loc(carr?.dh_validade)}</b></p>
                </Grid>
            </Row >
        )
    }

    render_carrinho() {
        const carr = this.props.carr ?? {}
        const ped_pendente = /12/.test(carr?.sit_pedido)
        const not_pedido = !carr?.sit_pedido
        const cheio = Array.isArray(carr?.list)
        return (
            <BoxWidget
                title={ped_pendente ? 'Pedido N. ' + this.props.carr?.numero : 'Itens selecionados'}
                subtitle=''>
                <BoxBody>
                    {this.render_tab_itens()}
                    <Row>
                        {cheio && <Grid cols="md-6">
                            {not_pedido && this.render_cupom()}
                            {this.render_total()}
                            {this.render_botoes()}
                            {/*<p className="lead"><b>Valor válido para contratar até:</b></p>*/}
                        </Grid>}
                        <Grid cols="md-6">
                            {carr?.imagemQrcode && this.render_qrcode(carr)}
                            {carr?.payment_url && this.render_link_pg(carr)}

                        </Grid>

                    </Row>
                </BoxBody>
            </BoxWidget>
        )
    }

    render_pedidos(dados) {
        if (dados.length === 0) {
            return (
                <CalloutInfo title="Nada por aqui...!">
                </CalloutInfo>
            )
        }

        return (<PedidoList dados={dados} getPedido={((id_pedido) =>{ this.props.obter_pedido_carrinho(id_pedido)})} />)
    }

    render_page() {
        const list = this.props.carr?.list
        return (
            <PageSetup title="Meus Pedidos">
                <ContentSection>
                    {Array.isArray(list) && <Row>
                        <Grid cols="md-12">
                            {this.render_carrinho()}
                        </Grid>
                    </Row>}
                    <Row>
                        <Grid cols="md-12">
                            {this.render_pedidos(this.props.pedidos?.lista)}
                        </Grid>
                    </Row>
                </ContentSection>
            </PageSetup>
        )
    }

    render() {
        return this.render_page()
    }

}

const mapStateToProps = state => ({
    carr: state.carrinho,
    pedidos: state.pedido,
    login: state.login,
    wcupom: '',
})
const mapDispatchToProps = dispatch => bindActionCreators({
    obter, obter_desconto, iniciar_pag, limpar, cancelar_pedido, obter_pedidos, obter_pedido_carrinho
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Carrinho)

