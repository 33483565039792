import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { logout } from '../login/login-actions'

import MenuItem from '../common/template/menu-item'
class MenuPrincipal extends Component {

    render() {
        return(
            <React.Fragment>
                <MenuItem route="/home" label="Home" icon="fa fa-home"/>
                <MenuItem route="/perfil-show" label="Meu Perfil" icon="glyphicon glyphicon-user"/>
                <MenuItem route="/minha-jornada" label="Minha Jornada" icon="glyphicon glyphicon-road"/>
                <MenuItem route="/pedidos" label="Meus Pedidos" icon="fa fa-shopping-basket"/>
                <MenuItem route="/extrato" label="Extrato Financeiro" icon="fa fa-bank"/>
                <MenuItem label="Sair" icon="fa fa-close" onClick={this.props.logout} />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({ login: state.login })
const mapDispatchToProps = dispatch => bindActionCreators({ logout }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(MenuPrincipal)
