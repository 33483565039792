import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PageSetup from '../main/page-setup'
import { ContentSection, Row, Grid } from '../common/layout'
import { ButtonDefault } from '../common/ui'

import ExtratoList from '../extrato/extrato-list'
import ExtratoContador from './extrato-contador'
import { listar, listar_mais } from './extrato-action'

class Extrato extends Component {

    componentDidMount() {
        this.props.listar([])
    }

    render_lancamentos(dados) {
        if (dados.length === 0)
            return

        return (<ExtratoList
            dados={dados}
        >
            {(this.props.extrato.tem_mais) &&
                <ButtonDefault onClick={() => {
                    this.props.listar_mais(this.props.extrato.page + 1, this.props.extrato.filtro)
                }}>Carregar mais...</ButtonDefault>
            }
        </ExtratoList>
        )
    }

    render() {
        return (
            <PageSetup title='Extrato Financeiro'>
                <ContentSection>
                    <Row>
                        <Grid cols='md-12'>
                            <ExtratoContador />
                            {this.render_lancamentos(this.props.extrato.lista)}
                        </Grid>
                    </Row>
                </ContentSection>
            </PageSetup>
        )
    }
}

const mapStateToProps = state => ({ extrato: state.extrato, login: state.login })
const mapDispatchToProps = dispatch => bindActionCreators({ listar, listar_mais }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Extrato)
