import act from '../main/action-const'

const INITIAL_STATE = {
    dados: {},
    lista: [],
    page: 1,
    tem_mais: false,
    contador: [],
    upd_status: 0, // 0 n/a
}

const DADOS_CLEAR = {
    id_extrato: 0,
    id_pessoa: 0,
    descricao: '',
    observacao: '',
    tipo_lanc: 0,
    valor: 0,
    desconto: 0,
    dt_venc: '',
    dh_liq: '',
    comprovante: ''
}

export default function (state = INITIAL_STATE, action) {

    switch (action.type) {

        case act.EXTRATO_LISTADO:
            return {
                ...state, lista: action.payload,
                upd_status: 0,
                tem_mais: (action.payload ?? []).length >= 50

            }

        case act.EXTRATO_LISTADO_MAIS:
            return {
                ...state, lista: [...state.lista, ...action.payload],
                tem_mais: (action.payload ?? []).length >= 50
            }

        case act.EXTRATO_ADD_PAGE:
            return {
                ...state, page: action.payload
            }

        case act.EXTRATO_CARREGADO:
            return { ...state, dados: action.payload, upd_status: 4 }

        case act.EXTRATO_LIMPAR:
            return { ...state, dados: DADOS_CLEAR }

        case act.EXTRATO_CARREGAR_CONTADOR:
            return {
                ...state, contador: action.payload
            }

        default:
            return state
    }
}
