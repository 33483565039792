export default {
    APP_DATA_UPDATE: 'app_data_update',
    TOKEN_VALIDADO: 'token_valido',
    USUARIO_CARREGADO: 'usuario_carregado',
    USUARIO_INVALIDO: 'usuario_invalido',
    USUARIO_TROCA_INVALIDA: 'usuario_troca_invalida',
    ACEITE_REALIZADO: 'aceite_realizado',
    GO_LOGIN_ESQUECI: 'go_login_esqueci',
    GO_USER_STATUS_CHANGE: 'go_user_status_change',

    CONFIG_LOAD: 'config_load',

    PERFIL_FOTO_CROP: 'perfil_foto_crop',
    PERFIL_CARREGADO: 'perfil_carregado',

    MEUS_CURSOS_LISTADOS: 'meus_cursos_listados',
    MEU_CURSO_CARREGADO: 'meu_curso_carregado',

    MEU_CURSO_AULAS_LISTADAS: 'meu_curso_aulas_listadas',
    MEU_CURSO_AULAS_CONTADOR_LISTADOS: 'meu_curso_aulas_contador_listados',
    MEU_CURSO_AULA_CARREGADA: 'meu_curso_aula_carregada',
    MEU_CURSO_AULAS_PERGUNTA_LISTADAS: 'meu_curso_aulas_pergunta_listadas',
    MEU_CURSO_AULAS_PERGUNTA_SET: 'meu_curso_aulas_pergunta_set',

    RESP_CARREGADO: 'resp_carregado',
    RESP_CHECK_UNCHECK: 'resp_check_uncheck',
    RESP_FINICHED: 'resp_finiched',
    RESP_NA: 'resp_na',
    RESP_CLEAR: 'resp_clear',

    QUEST_RESPOSTA_LISTADO: 'quest_resposta_listado',
    DASH_CONTADOR_CARREGADO: 'dash_contador_carregado',

    PROXIMO_CURSO_LISTADOS: 'proximo_curso_listados',
    PROXIMO_CURSO_CARREGADO: 'meu_curso_carregado',
    PROXIMO_CURSO_AULAS_LISTADAS: 'proximo_curso_aulas_listadas',
    PROXIMO_CURSO_INSCRICAO: 'proximo_curso_inscricao',

    CARRINHO_LISTADO: 'carrinho_listado',
    CARRINHO_CLEAR: 'carrinho_clear',

    PEDIDO_LISTADO: 'pedido_listado',
    PEDIDO_CLEAR: 'pedido_clear',

    JORNADA_LISTADA: 'jornada_listada',
    JORNADA_ADD_QUEST: 'jornada_add_quest',
    JORNADA_ADD_ANDAMENTO: 'jornada_add_andamento',
    JORNADA_CLEAR: 'jornada_clear',

    EXTRATO_LISTADO: 'extrato_listado',
    EXTRATO_LISTADO_MAIS: 'extrato_listado_mais',
    EXTRATO_ADD_PAGE: 'extrato_add_page',
    EXTRATO_CARREGADO: 'extrato_carregado',
    EXTRATO_LIMPAR: 'extrato_limpar',
    EXTRATO_CARREGAR_CONTADOR: 'extrato_carregar_contador',

}