import React from 'react'
import SimpleTable from '../common/tables/simple-table'
import { date_time_loc, money_loc } from '../sys/normalize-location'
import { pedido_status } from './pedido-status'
import { Anchor } from '../common/helper'

function icon_pix() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 50 50"
        >
            <path d="M25 .04c-2.16 0-4.2.84-5.73 2.37L9.68 12h3.25c1.6 0 3.11.62 4.24 1.76l6.77 6.77c.58.58 1.54.58 2.12-.01l6.77-6.76A5.929 5.929 0 0137.07 12h3.25l-9.59-9.59A8.058 8.058 0 0025 .04zM7.68 14l-5.27 5.27c-3.16 3.16-3.16 8.3 0 11.46L7.68 36h5.25c1.07 0 2.07-.42 2.83-1.17l6.77-6.77a3.502 3.502 0 014.94 0l6.77 6.77C35 35.58 36 36 37.07 36h5.25l5.27-5.27c3.16-3.16 3.16-8.3 0-11.46L42.32 14h-5.25c-1.07 0-2.07.42-2.83 1.17l-6.77 6.77A3.477 3.477 0 0125 22.96c-.9 0-1.79-.34-2.47-1.02l-6.77-6.77A4.004 4.004 0 0012.93 14H7.68zM25 29.037c-.385.001-.77.148-1.06.443l-6.77 6.76A5.929 5.929 0 0112.93 38H9.68l9.59 9.59A8.058 8.058 0 0025 49.96c2.16 0 4.2-.84 5.73-2.37L40.32 38h-3.25c-1.6 0-3.11-.62-4.24-1.76l-6.77-6.77a1.49 1.49 0 00-1.06-.433z"></path>
        </svg>
    )
}

const show_icon = (row, getPedido) => {
    if (row['tipo_pg'] === '47')
        return (<Anchor onClick={(() => getPedido(row['id_pedido']))}>
            <i className={`fa fa-credit-card fa-2x pull-left`}></i>
        </Anchor>)

    if (row['tipo_pg'] === '49')
        return (<Anchor onClick={(() => getPedido(row['id_pedido']))}>
            <i className={`fa fa-barcode fa-2x pull-left`}></i>
        </Anchor>)

    if (row['tipo_pg'] === '50')
        return (<Anchor onClick={(() => getPedido(row['id_pedido']))}>{icon_pix()}</Anchor >)

    return (
        <Anchor onClick={(() => getPedido(row['id_pedido']))}>
            <i className={`fa fa-shopping-basket fa-2x pull-left`}></i>
        </Anchor>)
}

export default function PedidoList(props) {
    return (
        <SimpleTable
            title="Pedidos"
            columns={[
                { name: 'icon', title: '', value: ((row) => { return show_icon(row, props.getPedido) }) },
                { name: 'numero', title: 'Nº' },
                { name: 'sit_pedido', title: 'Situação', value: ((row) => pedido_status(row['sit_pedido'], true)) },
                { name: 'dh_validade', title: 'Pg. Vencimento' },
                { name: 'subtotal', title: 'Valor', value: ((row) => money_loc(row['subtotal'])) },
                { name: 'desconto', title: 'Desconto Cupom', value: ((row) => money_loc(row['desconto'])) },
                { name: 'desconto_fpg', title: 'Desc. F.Pgto.', value: ((row) => money_loc(row['desconto_fpg'])) },
                { name: 'totalpagar', title: 'Total a Pagar', value: ((row) => money_loc(row['totalpagar'])) },
                { name: 'vl_pag', title: 'Vl. Pago', value: ((row) => { money_loc(row['vl_pg']) }) },
                { name: 'dh_pag', title: 'Dt. Pago', value: ((row) => { date_time_loc(row['vl_pg']) }) },
                { name: 'observacao', title: 'Observação' },
                { name: 'dh_change', title: 'Dt. Obs', value: ((row) => { date_time_loc(row['dh_change']) }) },
            ]}
            data={props.dados}
            row_key={(row) => ('id_pedido' + row['id_pedido'])}
        >
        </SimpleTable>
    )
}